import { ReactComponent as HomeMainImage } from 'assets/images/home_main_image.svg';

import './styles.css';

const Header = () => {
  return (
    <div className="header-container">
      <div className="header-text-container">
        <h1>Quality, de qualidade garantida.</h1>
        <h2>
          Na Quality você pode solicitar seu veículo com mais rapidez, segurança
          e praticidade.
        </h2>
      </div>

      <HomeMainImage className="header-image" />
    </div>
  );
};

export default Header;
