import { ThreeDots } from 'react-loader-spinner';

const ThreeDotsLoader = () => (
  <ThreeDots
    height="80"
    width="80"
    radius="9"
    color="#4fa94d"
    ariaLabel="three-dots-loading"
    visible={true}
    wrapperClass="login-loading"
  />
);

export default ThreeDotsLoader;
