import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { SpringPage } from 'utils/types/request.types';
import history from 'utils/history';
import { requestGetInvoices } from 'utils/requests/invoice/invoiceRequests';
import { CreatedInvoiceResponse } from 'utils/requests/ride/rideRequests.types';
import { DateFormatter } from 'utils/formatters/formatterResponse';

type UrlParams = {
  companyName: string;
  companyId: string;
};

const ListInvoiceByCompany = () => {
  const { companyName, companyId } = useParams<UrlParams>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoices, setInvoices] =
    useState<SpringPage<CreatedInvoiceResponse>>();

  const onClickInvoice = (invoiceId: number) => {
    history.push(
      `/myApp/menu/admin/company-invoice-by-id/${companyId}/${invoiceId}`,
    );
  };

  useEffect(() => {
    requestGetInvoices(companyId)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch(() => {
        Swal.fire({ title: 'Oops', text: 'Houve um erro', icon: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">{companyName} - fechamentos</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : invoices?.content.length ? (
          <>
            <h2 className="page-subtitle">
              Ao clicar em um fechamento, você pode ver seus detalhes
            </h2>
            <div className="overflow">
              <table className="table-container">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>STATUS</th>
                    <th>CRIADO EM</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.content.map((invoice) => (
                    <tr
                      key={invoice.id}
                      className="table-animation-effect"
                      onClick={() => onClickInvoice(invoice.id)}
                    >
                      <td>{invoice.id}</td>
                      <td>
                        {invoice.status[invoice.status.length - 1].status}
                      </td>
                      <td>{DateFormatter(invoice.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <h2 className="page-subtitle">
            Ainda não há fechamento para esta empresa
          </h2>
        )}
      </div>
    </div>
  );
};

export default ListInvoiceByCompany;
