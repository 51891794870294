import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SwalAlertError } from 'utils/SwalAlert/SwalAlert';
import { RideDateFormatter } from 'utils/formatters/formatterResponse';
import { requestBackendRidesByEntity } from 'utils/requests/ride/rideRequests';

import { SpringPage } from 'utils/types/request.types';
import { EntitiesType } from 'utils/types/entities.types';
import { AllRidesResponse } from 'utils/requests/ride/rideRequests.types';

import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';
import { hasAnyRoles } from 'utils/requests/auth/auth';

type ControlComponentsData = {
  activePage: number;
};

type Props = {
  requestEntity: EntitiesType;
};

const RideHistory = ({ requestEntity }: Props) => {
  const history = useHistory();

  const [page, setPage] = useState<SpringPage<AllRidesResponse>>();
  const [isLoading, setIsloading] = useState<boolean>();
  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({
      activePage: 0,
    });

  const errorText = useMemo(() => {
    return {
      title: 'Oops',
      content: 'Erro ao fazer trazer corridas',
    };
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getRaces = useCallback(() => {
    requestBackendRidesByEntity(controlComponentsData.activePage, requestEntity)
      .then((response) => {
        setPage(response.data);
      })
      .catch(() => {
        SwalAlertError(errorText).then(() => {
          history.push('/myapp/menu');
        });
      })
      .finally(() => {
        setIsloading(false);
      });
  }, [controlComponentsData, errorText, history, requestEntity]);

  const onClickRideItem = (rideId?: number) => {
    history.push(`/myapp/menu/ride-history/${rideId}`);
  };

  useEffect(() => {
    setIsloading(true);
    getRaces();
  }, [getRaces]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container ">
        <h1 className="page-title">Histórico de corridas</h1>
        {isLoading ? (
          <ThreeDotsLoader />
        ) : page?.content.length ? (
          <div className="overflow">
            <table className="table-container">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATA</th>

                  {!hasAnyRoles(['ROLE_OPERATOR']) && (
                    <>
                      <th>FAVORECIDO</th>
                      <th>MOTORISTA</th>
                      <th>STATUS</th>
                      <th>EMPRESA</th>
                    </>
                  )}

                  {hasAnyRoles(['ROLE_OPERATOR']) && <th>HORÁRIO</th>}
                  {hasAnyRoles(['ROLE_OPERATOR']) && <th>ORIGEM</th>}
                  {hasAnyRoles(['ROLE_OPERATOR']) && <th>DESTINO</th>}
                  {hasAnyRoles(['ROLE_OPERATOR']) && <th>MOTORISTA</th>}
                </tr>
              </thead>
              <tbody>
                {page.content.map((ride) => (
                  <tr
                    key={ride.ride.id}
                    className="table-animation-effect"
                    onClick={() => onClickRideItem(ride.ride.id)}
                  >
                    <td>{`#${ride.ride.id}`}</td>
                    <td>{RideDateFormatter(ride.ride.scheduleDate)}</td>

                    {!hasAnyRoles(['ROLE_OPERATOR']) && (
                      <>
                        <td>
                          {ride.passenger.firstName +
                            ' ' +
                            ride.passenger.lastName}
                        </td>
                        <td>
                          {ride.car?.driver
                            ? ride.car.driver.firstName +
                              ' ' +
                              ride.car.driver.lastName
                            : 'Não alocado'}
                        </td>
                        <td>{ride.status[ride.status.length - 1].status}</td>
                        <td>{ride.passenger.company.name}</td>
                      </>
                    )}

                    {hasAnyRoles(['ROLE_OPERATOR']) && (
                      <>
                        <td>{ride.ride.scheduleTime}</td>
                        <td>{ride.ride.originCity}</td>
                        <td>{ride.ride.destinyCity}</td>
                        <td>
                          {ride.car?.driver
                            ? ride.car.driver.firstName +
                              ' ' +
                              ride.car.driver.lastName
                            : 'Não alocado'}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h3 className="page-subtitle">Não há histórico de corridas</h3>
        )}

        {page && page?.content.length > 0 && (
          <div className="mt-5">
            <Pagination
              pageCount={page ? page.totalPages : 0}
              pageRange={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RideHistory;
