import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import { AuthContext } from 'contex/AuthContext';

import { getAuthData, removeAuthData } from 'utils/storage';
import { SaveCarRequest } from 'utils/requests/car/car.types';
import {
  requestBackCarById,
  requestBackEndSaveCar,
  requestBackUpdateCar,
} from 'utils/requests/car/carRequests';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import './styles.css';

type Props = {
  isEditing?: boolean;
};

const RegisterDocumentsDriver = ({ isEditing }: Props) => {
  const history = useHistory();
  const [hasError, setHasError] = useState<boolean>(false);
  const [carId, setCarId] = useState<number>();

  const { setAuthContextData } = useContext(AuthContext);

  const driverId = getAuthData().userId;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveCarRequest>();

  useEffect(() => {
    setValue('driver.id', driverId);
  }, [driverId, setValue]);

  useEffect(() => {
    requestBackCarById(driverId).then((response) => {
      const responseData: SaveCarRequest = response.data;

      setCarId(responseData.id);
      setValue('aso', responseData.aso);
      setValue('carYear', responseData.carYear);
      setValue('color', responseData.color);
      setValue('document', responseData.document);
      setValue('plate', responseData.plate);
      setValue('security', responseData.security);
      setValue('vehicle', responseData.vehicle);
    });
  });

  const onSubmit = (formData: SaveCarRequest) => {
    if (isEditing) {
      requestBackUpdateCar(formData, carId)
        .then(() => {
          toast.success('Informações atualizadas');
        })
        .catch(() => {
          toast.error('Falha ao atualizar informações');
        });
    } else {
      requestBackEndSaveCar(formData)
        .then(() => {
          Swal.fire('Sucesso!', 'Documentos atualizados!', 'success').then(
            () => {
              Swal.fire(
                '',
                'Faça login novamente para atualizar seus dados',
                'info'
              ).then(() => {
                removeAuthData();
                setAuthContextData({
                  authenticated: false,
                });
                history.replace('/login/auth/enter');
              });
            }
          );
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };

  return (
    <div className="container">
      <div className="base-card register-form-container mt-5">
        {isEditing ? (
          <>
            <h1 className="page-title">
              Atualização de informações do motorista
            </h1>
            <h2 className="page-subtitle text-center mb-5">
              Atualize aqui as informações de seu carro:
            </h2>
          </>
        ) : (
          <>
            <h1 className="page-title">Cadastro de informações do motorista</h1>
            <h2 className="page-subtitle text-center mb-5">
              Para seguir no aplicativo, cadastre as seguintes informações:
            </h2>
          </>
        )}

        {hasError && (
          <div className="alert alert-danger">
            Erro ao tentar cadastrar informações
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <input
              {...register('vehicle', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.vehicle ? 'is-invalid' : ''
              }`}
              placeholder="Veículo"
              name="vehicle"
            />
            <div className="invalid-feedback d-block">
              {errors.vehicle?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('carYear', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.carYear ? 'is-invalid' : ''
              }`}
              placeholder="Ano"
              name="carYear"
            />
            <div className="invalid-feedback d-block">
              {errors.carYear?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('color', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.color ? 'is-invalid' : ''
              }`}
              placeholder="Cor"
              name="color"
            />
            <div className="invalid-feedback d-block">
              {errors.color?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('plate', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.plate ? 'is-invalid' : ''
              }`}
              placeholder="Placa"
              name="plate"
            />
            <div className="invalid-feedback d-block">
              {errors.plate?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('document', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.document ? 'is-invalid' : ''
              }`}
              placeholder="Documento"
              name="document"
            />
            <div className="invalid-feedback d-block">
              {errors.document?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('security', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.security ? 'is-invalid' : ''
              }`}
              placeholder="Segurança"
              name="security"
            />
            <div className="invalid-feedback d-block">
              {errors.security?.message}
            </div>
          </div>

          <div className="mb-5">
            <input
              {...register('aso', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.aso ? 'is-invalid' : ''
              }`}
              placeholder="ASO"
              name="aso"
            />
            <div className="invalid-feedback d-block">
              {errors.aso?.message}
            </div>
          </div>

          <ButtonIcon
            text={
              isEditing ? 'Atualizar informações ' : 'Cadastrar informações'
            }
          />
        </form>
      </div>
    </div>
  );
};

export default RegisterDocumentsDriver;
