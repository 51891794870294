export const CarouselVehicles = [
  {
    "name": "Van executiva",
    "description": "Perfeita para viagens em grupo, essa categoria proporciona conforto, praticidade e segurança para você e seus passageiros",
    "imageUrl": require("assets/images/vehicles/luxury_van.png").default,
    "id": 1
  },
  {
    "name": "Leve comum",
    "description": "Veículos mais usados para os afazeres diários como levar as crianças a escola ou ir ao trabalho sem preocupações.",
    "imageUrl": require("assets/images/vehicles/regular_light.png").default,
    "id": 2
  },
  {
    "name": "Leve executivo",
    "description": "Para você que procura por mais conforto, sofisticação e veículos que entregam uma maior agilidade para seus compromissos mais importantes.",
    "imageUrl": require("assets/images/vehicles/luxury_light.png").default,
    "id": 3
  },
  {
    "name": "Minivan",
    "description": "Ideal para quem procura mais mobilidade, mas sem perder o espaço que necessita para completar suas tarefas.",
    "imageUrl": require("assets/images/vehicles/minivan.png").default,
    "id": 4
  },
  {
    "name": "Van",
    "description": "Se você procura realizar entregas de uma forma mais rápida e eficaz a Van é a categoria certa para você.",
    "imageUrl": require("assets/images/vehicles/van.png").default,
    "id": 5
  }
]
