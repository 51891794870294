import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineCopyrightCircle,
} from 'react-icons/ai';

import { ReactComponent as MainLogoWhite } from 'assets/images/main_logo_white.svg';

import './styles.css';

const MainFooter = () => {
  return (
    <div className="footer-container">
      <div className="footer-content-container">
        <MainLogoWhite className="footer-logo"/>

        <div className="footer-information-container">
          <h1>Reserve um veículo com a Quality</h1>
          <p>
            A Quality busca sempre melhorar a vida dos seus usuários, com
            rapidez e mobilidade de uma forma mais dinâmica, para que seu tempo
            seja aproveitado da melhor forma possível.
          </p>
        </div>

        <div className="footer-information-container">
          <h1>Contato</h1>
          <p>(11) 2299-0099</p>
          <p>qualitynet@email.com</p>
        </div>

        <div className="footer-social-media-container">
          <AiFillFacebook />
          <AiOutlineTwitter />
          <AiOutlineInstagram />
        </div>
      </div>

      <div className="footer-copyright-container">
        <AiOutlineCopyrightCircle />
        <section>2024 - Quality Logística LTDA</section>
      </div>
    </div>
  );
};

export default MainFooter;
