import axios from 'axios';
import { getAuthData } from 'utils/storage';
import { BASE_URL } from '../requests';
import { ControlComponentsData, SaveInvoice } from 'utils/types/entities.types';
import { RequestProcessInvoice } from 'utils/types/request.types';

export const requestGetFaturatedRides = (activePage: ControlComponentsData) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: '/rides?faturated=true&&invoiced=false',
    headers,
    params: {
      page: activePage.activePage,
      size: 10,
      origincity: activePage.filterData?.origincity,
      destinyCity: activePage.filterData?.destinycity,
      companyId: activePage.filterData?.companyId?.id,
    },
  });
};

export const requestSaveInvoice = (data: SaveInvoice) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/invoices',
    headers,
    data,
  });
};

export const requestGetInvoices = (companyId: string, activePage?: number) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/invoices?companyId=${companyId}`,
    headers,
    params: {
      page: activePage,
      size: activePage ? 7 : undefined,
    },
  });
};

export const requestGetInvoiceById = (companyId: string, invoiceId: string) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/invoices?companyId=${companyId}&invoiceId=${invoiceId}`,
    headers,
  });
};

export const requestProcessInvoice = (data: RequestProcessInvoice) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/invoices/process-invoice',
    headers,
    data,
  });
};
