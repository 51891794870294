import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import { SpringPage } from 'utils/types/request.types';
import { DateFormatter } from 'utils/formatters/formatterResponse';
import { AllRidesResponse } from 'utils/requests/ride/rideRequests.types';
import { findPendingRideFaturation } from 'utils/requests/ride/rideRequests';

import './Styles.css';

const FaturationRidesAdmin = () => {
  const history = useHistory();
  const [faturationRides, setFaturationRides] =
    useState<SpringPage<AllRidesResponse>>();

  const onClickFaturationRide = (rideId: number) => {
    history.push(`/myapp/menu/ride-history/${rideId}`);
  };

  useEffect(() => {
    findPendingRideFaturation()
      .then((response) => {
        setFaturationRides(response.data);
      })
      .catch(() => {
        Swal.fire('Oops', 'Houve um problema', 'error');
      });
  }, []);

  return (
    <div className="faturation-rides-container base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Corridas a faturar</h1>
        <h2 className="page-subtitle">
          Clique em uma corrida e adicione custos
        </h2>
        {faturationRides?.content.length ? (
          <div className="overflow">
            <table className="table-container">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATA</th>
                  <th>FAVORECIDO</th>
                  <th>MOTORISTA</th>
                  <th>STATUS</th>
                  <th>EMPRESA</th>
                </tr>
              </thead>
              <tbody>
                {faturationRides.content.map((ride) => (
                  <tr
                    key={ride.ride.id}
                    className="table-animation-effect"
                    onClick={() => onClickFaturationRide(ride.ride.id!)}
                  >
                    <td>{`#${ride.ride.id}`}</td>
                    <td>{DateFormatter(ride.ride.scheduleDate)}</td>
                    <td>
                      {ride.passenger.firstName + ' ' + ride.passenger.lastName}
                    </td>
                    <td>
                      {ride.car?.driver
                        ? ride.car.driver.firstName +
                          ' ' +
                          ride.car.driver.lastName
                        : 'Não alocado'}
                    </td>
                    <td>{ride.status[ride.status.length - 1].status}</td>
                    <td>{ride.passenger.company.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h1>Sem corridas para faturar</h1>
        )}
      </div>
    </div>
  );
};

export default FaturationRidesAdmin;
