import Header from './components/Header';
import Card from './components/Card';
import VehiclesCategory from './components/VehiclesCategory';
import MainFooter from 'components/MainFooter';

import { SystemQualities } from './data/system_qualities';

import './styles.css';

const Home = () => {
  return (
    <div className="home-container">
      <Header />

      <div className="home-cards-container">
        <h1 className="home-cards-title">Escolha Qualidade</h1>
        <div className="home-cards-qualities">
          {SystemQualities.map((quality) => (
            <Card
              id={quality.id}
              key={quality.id}
              description={quality.description}
              name={quality.name}
            />
          ))}
        </div>
      </div>

      <VehiclesCategory />

      <MainFooter />
    </div>
  );
};

export default Home;
