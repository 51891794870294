import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';
import CurrentDriverRide from '../../../CurrentDriverRide';
import { usePendingRidesToAccept } from '../../customHooks';

import './styles.css';

const PendingRidesToAccept = () => {
  const { isLoading, requestedRide } = usePendingRidesToAccept();

  return (
    <div className="pending-ride-to-accept-container base-card ">
      <div className="container">
        {isLoading ? (
          <ThreeDotsLoader />
        ) : requestedRide.length ? (
          <>
            <h1 className="page-title">Corridas solicitadas</h1>
            <h2 className="page-subtitle">
              Confira as solicitações de corrida abaixo
            </h2>
            <CurrentDriverRide requestedRides={requestedRide} />
          </>
        ) : (
          <>
            <h1 className="page-title">Não há solicitação</h1>
            <h2 className="page-subtitle">
              Sem solicitação de corrida no momento
            </h2>
          </>
        )}
      </div>
    </div>
  );
};

export default PendingRidesToAccept;
