// HOOKS
import { usePedingRidesToStart } from '../../customHooks';

// UTILS
import { onStartRide } from '../../utils';
import { RideDateFormatter } from 'utils/formatters/formatterResponse';

const PendingRidesToStart = () => {
  const { rides } = usePedingRidesToStart();

  return (
    <div className="base-card p-4 mt-5">
      {rides?.content.length ? (
        <>
          <h1 className="page-title">Corridas pendentes de início</h1>
          <h2 className="page-subtitle">
            Clique em uma corrida para iniciá-la
          </h2>
          <div className="overflow">
            <table className="table-container">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DATA</th>
                  <th>HORÁRIO</th>
                  <th>ORIGEM</th>
                  <th>DESTINO</th>
                </tr>
              </thead>
              <tbody>
                {rides.content.map((ride) => (
                  <tr
                    key={ride.ride.id}
                    className="table-animation-effect"
                    onClick={() => onStartRide(ride.ride.id)}
                  >
                    <td>{`#${ride.ride.id}`}</td>
                    <td>{RideDateFormatter(ride.ride.scheduleDate)}</td>
                    <td>{ride.ride.scheduleTime}</td>
                    <td>
                      {ride.ride.originCity}
                    </td>
                    <td>{ride.ride.destinyCity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>
          <h1 className="page-title">Corridas para iniciar</h1>
          <h2 className="page-subtitle">
            Corridas aceitas e pendentes de início aparecerão aqui
          </h2>
        </div>
      )}
    </div>
  );
};

export default PendingRidesToStart;
