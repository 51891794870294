export const SystemQualities = [
  {
    id: 1,
    name: 'Os melhores veículos',
    description:
      'Somente na Quality você encontra veículos modernos, para que você se sinta melhor. ',
  },
  {
    id: 2,
    name: 'Mais segurança para você',
    description:
      'Aqui você também conta com um processo de solicitação que protege seus dados.',
  },
  {
    id: 3,
    name: 'É prático e digital',
    description:
      'Solicitar um veículo é rápido, prático e digital, para que você não perca nada importante',
  },
];
