import ItemDetails from 'components/ItemDetails';
import { Car } from 'utils/types/entities.types';

type Props = {
  carData: Car | null;
};

const DriverDetails = (props: Props) => {
  const { carData } = props;

  return (
    <div className="base-card p-3 mt-5">
      {carData?.driver ? (
        <>
          <ItemDetails
            itemTitle="Nome do motorista"
            itemData={`${
              carData.driver?.firstName + ' ' + carData.driver.lastName
            }`}
          />
          <ItemDetails itemTitle="Carro" itemData={carData.vehicle} />
          <ItemDetails itemTitle="Placa" itemData={carData.plate} />
          <ItemDetails itemTitle="Cor do carro" itemData={carData.color} />
        </>
      ) : (
        <div className="container">
          <h1>Motorista ainda não atribuido</h1>
        </div>
      )}
    </div>
  );
};

export default DriverDetails;
