import axios from 'axios';

import { BASE_URL } from '../requests';

import { getAuthData } from 'utils/storage';

import { SaveCarRequest } from './car.types';

export const requestBackEndSaveCar = (saveCarData: SaveCarRequest) => {
  const token = getAuthData().access_token;

  const data = JSON.stringify(saveCarData);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/cars',
    headers,
    data,
  });
};

export const requestBackCarById = (driverId: number) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/cars/${driverId}`,
    headers,
  });
};

export const requestBackUpdateCar = (
  saveCarData: SaveCarRequest,
  carId?: number
) => {
  const token = getAuthData().access_token;
  const data = JSON.stringify(saveCarData);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `/cars/${carId}`,
    headers,
    data,
  });
};
