import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import ItemDetails from 'components/ItemDetails';

import { SwalAlertError } from 'utils/SwalAlert/SwalAlert';
import { Company, Passenger } from 'utils/types/entities.types';
import { requestCompanyById } from 'utils/requests/company/companyRequests';
import { requestBackEndDeleteManager } from 'utils/requests/user/userRequests';

import './styles.css';

type UrlParams = {
  companyName: string;
  companyId: string;
};

const CompanyDetails = () => {
  const { companyName, companyId } = useParams<UrlParams>();
  const history = useHistory();

  const [companyData, setCompanyData] = useState<Company>();
  const [companyPassengers, setCompanyPassengers] = useState<Passenger[]>();
  const [companyManagers, setCompanyManagers] = useState<Passenger[]>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onClickDeleteManager = (manager: Passenger) => {
    Swal.fire({
      title: 'Deletar gerente',
      text: `Deseja realmente deletar ${
        manager.firstName + ' ' + manager.lastName + ' ?'
      }`,
      icon: 'question',
      confirmButtonText: 'Deletar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        requestBackEndDeleteManager(String(manager.id))
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Gerente deletado com sucesso',
              timer: 2300,
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Houve um erro ao tentar deletar este gerente',
            });
          });
      }
    });
  };

  useEffect(() => {
    requestCompanyById(companyId)
      .then((response) => {
        setCompanyData(response.data);
      })
      .catch(() => {
        SwalAlertError({ title: 'Oops', content: 'Algo deu errado' }).finally(
          () => {
            history.push('/');
          },
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId, history]);

  useEffect(() => {
    if (companyData) {
      setCompanyPassengers(
        companyData.user.filter(
          (user) => user.roles[0].authority === 'ROLE_PASSENGER',
        ),
      );

      setCompanyManagers(
        companyData.user.filter(
          (user) => user.roles[0].authority === 'ROLE_COMPANY',
        ),
      );
    }
  }, [companyData]);

  return (
    <div className="company-details-container base-card">
      <div className="container">
        {isLoading ? (
          <ThreeDots />
        ) : (
          <>
            {companyData && (
              <>
                <h1>{companyData.name}</h1>

                <div className="company-details-infos-contact-container">
                  <h1 className="company-details-user-title">Informações</h1>
                  <ItemDetails
                    itemTitle="Endereço"
                    itemData={companyData.address}
                  />
                  <ItemDetails
                    itemTitle="Telefone"
                    itemData={companyData.celphone}
                  />
                  <ItemDetails itemTitle="Email" itemData={companyData.email} />
                  <ItemDetails
                    itemTitle="Preço por Km"
                    itemData={companyData.kmPrice}
                  />
                </div>
              </>
            )}

            <h1 className="company-details-user-title mt-5">Passageiros</h1>
            {companyPassengers?.length ? (
              <div className="overflow">
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NOME</th>
                      <th>EMAIL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyPassengers?.map((passenger) => (
                      <tr className="table-animation-effect">
                        <td>{passenger.id}</td>
                        <td>{passenger.firstName + '' + passenger.lastName}</td>
                        <td>{passenger.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1 className="company-details-user-title">
                Não há passageiro cadastrado
              </h1>
            )}

            <h1 className="company-details-user-title mt-5">Gerentes</h1>

            {companyManagers?.length ? (
              <div className="overflow">
                <table className="table-container">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NOME</th>
                      <th>EMAIL</th>
                      <th>FATURAMENTO</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {companyManagers?.map((manager) => (
                      <tr className="table-animation-effect">
                        <td>{manager.id}</td>
                        <td>{manager.firstName + ' ' + manager.lastName}</td>
                        <td>{manager.email}</td>
                        <td>{manager.faturation ? 'Sim' : 'Não'}</td>
                        <td onClick={() => onClickDeleteManager(manager)}>
                          <MdOutlineDeleteForever className="company-manager-delete" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1 className="company-details-user-title">
                Não há gerente cadastrado
              </h1>
            )}

            <div className="company-details-btn-container">
              <Link
                className="btn btn-success"
                to={`/myApp/menu/admin/registerManager/${companyId}/${companyName}`}
              >
                Cadastrar gerente
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
