import Swal from 'sweetalert2';
import { requestSendDriverRideAcceptance } from 'utils/requests/fowarding/fowardingRequests';
import { DriverAcceptanceRequest } from 'utils/requests/fowarding/fowardingRequests.types';

type SwalRideAcceptanceTypes = {
  accepted: boolean;
  requestData: DriverAcceptanceRequest;
};

export const SwalRideAcceptance = ({
  accepted,
  requestData,
}: SwalRideAcceptanceTypes) => {
  Swal.fire({
    title: accepted ? 'Aceitar!' : 'Rejeitar!',
    text: accepted ? 'Aceitar corrida' : 'Rejeitar corrida',
    icon: accepted ? 'info' : 'question',
    iconColor: '#ecd60e',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: accepted ? 'Aceitar!' : 'Rejeitar!',
    cancelButtonText: 'Cancelar',
  }).then((result) => {
    if (result.isConfirmed) {
      requestSendDriverRideAcceptance(requestData)
        .then(() => {
          Swal.fire({
            title: accepted ? 'Corrida aceita' : 'Corrida rejeitada',
            text: accepted ? 'Você será redirecionado' : '',
            icon: accepted ? 'success' : 'warning',
          });
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        })
        .catch(() => {
          Swal.fire('Oops', 'Erro ao processar informações', 'error');
        });
    }
  });
};
