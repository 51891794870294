import AdminNavBar from './AdminNavBar';
import DriverNavBar from './DriverNavBar';
import ManagerNavBar from './ManagerNavBar';
import PassengerNavbar from './PassengerNavBar';

import { getAuthData } from 'utils/storage';
import { hasAnyRoles } from 'utils/requests/auth/auth';

import LoginRedirect from 'pages/LoginRedirect';

import './styles.css';
import OperatorNavBar from './OperatorNavBar';

const Navbar = () => {
  return (
    <nav className="my-app-nav-container">
      <div className="my-app-nav-wrapper">
        <ul className="navigation-items-container">
          {!getAuthData().access_token && <LoginRedirect />}

          {hasAnyRoles(['ROLE_PASSENGER']) && <PassengerNavbar />}

          {hasAnyRoles(['ROLE_ADMIN']) && <AdminNavBar />}

          {hasAnyRoles(['ROLE_COMPANY']) && <ManagerNavBar />}

          {hasAnyRoles(['ROLE_DRIVER']) && <DriverNavBar />}

          {hasAnyRoles(['ROLE_OPERATOR']) && <OperatorNavBar />}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
