import axios from 'axios';

import { getAuthData } from 'utils/storage';

import { BASE_URL } from '../requests';

export const requestBackendAllDrivers = () => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: '/users/driver',
    headers,
  });
};

export const requestBackendDriverById = () => {
  const token = getAuthData().access_token;
  const id = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/users/driver/${id}`,
    headers,
  });
};

export const getRidesByDriverId = () => {
  const token = getAuthData().access_token;
  const id = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/driver/${id}`,
    headers,
  });
};

export const getRidesAcceptedByDriverId = () => {
  const token = getAuthData().access_token;
  const id = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/driver/${id}/accepted`,
    headers,
  });
};
