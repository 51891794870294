import jwtDecode from 'jwt-decode';
import { getAuthData } from '../../storage';
import axios from 'axios';
import qs from 'qs';
import { CLIENT_ID, CLIENT_SECRET, BASE_URL } from 'utils/requests/requests';
import { LoginData } from 'utils/types/request.types';

export type Role =
  | 'ROLE_PASSENGER'
  | 'ROLE_ADMIN'
  | 'ROLE_COMPANY'
  | 'ROLE_DRIVER'
  | 'ROLE_OPERATOR';

export type TokenData = {
  exp: number;
  user_name: string;
  authorities: Role[];
};

export const getTokenData = (): TokenData | undefined => {
  try {
    return jwtDecode(getAuthData().access_token) as TokenData;
  } catch (error) {
    return undefined;
  }
};

export const isAuthenticated = (): boolean => {
  let tokenData = getTokenData();
  return tokenData && tokenData.exp * 1000 > Date.now() ? true : false;
};

export const hasAnyRoles = (roles: Role[]): boolean => {
  if (roles.length === 0) {
    return true;
  }

  const tokenData = getTokenData();

  if (tokenData !== undefined) {
    for (var i = 0; i < roles.length; i++) {
      if (tokenData.authorities.includes(roles[i])) {
        return true;
      }
    }
    //return roles.some(role => tokenData.authorities.includes(role));
  }

  return false;
};

export const requestBackendLogin = (loginData: LoginData) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + window.btoa(CLIENT_ID + ':' + CLIENT_SECRET),
  };

  const data = qs.stringify({
    ...loginData,
    grant_type: 'password',
  });

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/oauth/token',
    data,
    headers,
  });
};

export const hasDocuments = async () => {
  const token = getAuthData().access_token;
  const driverId = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return await axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `users/driver/${driverId}`,
    headers,
  });
};

export const requestBackendRecoverPasswordCode = (email: string) => {
  const data = {
    email: email,
  };
  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/passwords',
    data,
  });
};

export const requestBackendChangePassword = (
  email: string,
  password: string,
  code: string,
) => {
  const data = {
    email: email,
    password: password,
    code: code,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/passwords/recover',
    data,
  });
};
