import { Dispatch, SetStateAction } from 'react';

// THIRD PARTY LIBS
import Swal from 'sweetalert2';

// UTILS
import history from 'utils/history';
import { getAuthData } from 'utils/storage';
import { setHappeningRide } from 'utils/requests/ride/rideRequests';
import { getRidesAcceptedByDriverId } from 'utils/requests/driver/driverRequests';

// TYPES
import { SpringPage } from 'utils/types/request.types';
import { AllRidesResponse } from 'utils/requests/ride/rideRequests.types';
import { requestFindPendingRideByDriver } from 'utils/requests/fowarding/fowardingRequests';
import { FindPendingRideByDriverResponse } from 'utils/requests/fowarding/fowardingRequests.types';

export async function getRidesAcceptedByDriver(
  onSucess: Dispatch<SetStateAction<SpringPage<AllRidesResponse> | undefined>>,
) {
  const hasDocuments = getAuthData().hasDriverDocument;

  if (hasDocuments) {
    getRidesAcceptedByDriverId()
      .then((response) => {
        onSucess(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops',
          text: 'Houve um problema em trazer informações',
          icon: 'error',
        });
      });
  }
}

export async function getPendingRidesToAccept(
  setRequestRide: Dispatch<SetStateAction<FindPendingRideByDriverResponse[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) {
  const hasDocuments = getAuthData().hasDriverDocument;
  if (hasDocuments) {
    requestFindPendingRideByDriver()
      .then((response) => {
        setRequestRide(response.data);
      })
      .catch(() => {
        Swal.fire('Oops', 'Erro ao trazer informações', 'error').then(() => {
          history.push('/myapp/menu');
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  } else {
    history.goBack();
  }
}

export function onStartRide(rideId?: number) {
  Swal.fire({
    title: 'Deseja iniciar esta corrida?',
    text: `A corrida de ID ${rideId} será iniciada`,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Cancelar',
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      setHappeningRide(rideId!).then(() => {
        history.push('/myapp/menu/ride/current-ride');
      });
    }
  });
}
