import { useEffect, useState } from 'react';

import ManagerInvoice from './ManagerInvoice';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { getAuthData } from 'utils/storage';
import { requestBackEndUserById } from 'utils/requests/user/userRequests';
import { CompanyUser } from 'utils/requests/company/companyRequests.types';

import { ReactComponent as DashboardImg } from 'assets/images/welcome_manager_dashboard.svg';

import './styles.css';

const ManagerDashboard = () => {
  const [user, setUser] = useState<CompanyUser>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const managerId = getAuthData().userId;

    requestBackEndUserById(String(managerId))
      .then((response) => {
        setUser(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <div className="manager-dashoboard-loader-container">
      <ThreeDotsLoader />
    </div>
  ) : user && user.faturation ? (
    <ManagerInvoice />
  ) : (
    <div className="regular-manager-dashoboard-container">
      <h1>Seja bem-vindo ao Quality App</h1>
      <DashboardImg />
    </div>
  );
};

export default ManagerDashboard;
