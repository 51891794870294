import { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ListItem from 'components/UserCard';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { SwalAlertError } from 'utils/SwalAlert/SwalAlert';
import { CompanyDataResponse } from 'utils/requests/company/companyRequests.types';
import { requestBackEndAllCompanies } from 'utils/requests/company/companyRequests';

import './styles.css';

const MyCompaniesAdmin = () => {
  const history = useHistory();

  const [companies, setCompanies] = useState<CompanyDataResponse[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  const errorText = useMemo(() => {
    return {
      title: 'Oops',
      content: 'Erro ao trazer empresas',
    };
  }, []);

  useEffect(() => {
    requestBackEndAllCompanies()
      .then((response) => {
        setCompanies(response.data.content);
        setIsloading(false);
      })
      .catch(() => {
        SwalAlertError(errorText).then(() => {
          history.push('/myapp/menu');
        });
      });
  }, [errorText, history]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Empresas parceiras cadastradas</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : companies?.length ? (
          <>
            <h3 className="page-subtitle">
              Ao clicar em uma empresa parceira, você pode cadastrar um gerente
              parceiro
            </h3>
            <div className="mt-3">
              {companies.map((company) => (
                <Link
                  to={`/myApp/menu/admin/my-companies/${company.id}/${company.name}`}
                  key={company.id}
                >
                  <ListItem key={company.id} firstName={company.name} />
                </Link>
              ))}
            </div>
          </>
        ) : (
          <h3 className="page-subtitle mt-3 mb-3 text-secondary">
            Ainda não há empresa parceira cadastrada.
          </h3>
        )}

        <div className="text-end">
          <button className="btn btn-secondary mt-5">
            <Link to="/myApp/menu/admin/my-companies/register-company">
              Registre uma empresa parceira
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCompaniesAdmin;
