import { createContext } from 'react';

import { TokenData } from 'utils/requests/auth/auth';

export type AuthContextData = {
  authenticated: boolean;
  tokenData?: TokenData;
  userId?: number;
  userDocument?: boolean;
};

export type AuthContextType = {
  authContextData: AuthContextData;
  setAuthContextData: (authContextData: AuthContextData) => void;
};

export const AuthContext = createContext<AuthContextType>({
  authContextData: {
    authenticated: false,
    userId: undefined,
    userDocument: false,
  },
  setAuthContextData: () => null,
});
