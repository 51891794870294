import { formatExpenseValue } from 'utils/formatters/formatterMoney';
import { formatExpenseTitle } from './helpers';

import Swal from 'sweetalert2';
import { MdOutlineDeleteForever } from 'react-icons/md';

import { hasAnyRoles } from 'utils/requests/auth/auth';
import { requestDeleteExpense } from 'utils/requests/expenses/expensesRequest';

import './styles.css';

type Props = {
  itemTitle: string;
  itemData?: string | number;
  expenseType?: boolean;
  expenseId?: number;
  onFetchRide?: () => void;
};

const ItemDetails = ({
  itemData,
  itemTitle,
  expenseType,
  expenseId,
  onFetchRide,
}: Props) => {
  const onClickDeleteExpense = () => {
    Swal.fire({
      title: 'Deletar custo',
      text: `Deseja realmente deletar ${formatExpenseTitle(itemTitle)}`,
      icon: 'question',
      confirmButtonText: 'Deletar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        requestDeleteExpense(expenseId!)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Custo deletado',
              timer: 2300,
            });

            onFetchRide && onFetchRide();
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Houve um erro ao tentar deletar este custo',
            });
          });
      }
    });
  };

  return (
    <div
      className={`race-details-item-container ${
        expenseType ? 'expense-item-container' : ''
      }`}
    >
      {expenseType ? (
        <>
          <div>
            <h4 className="me-2">{formatExpenseTitle(itemTitle)}</h4>
            <p>
              {String(formatExpenseValue(Number(itemData))).replace('.', ',')}
            </p>
          </div>
          {hasAnyRoles(['ROLE_ADMIN', 'ROLE_OPERATOR']) && (
            <MdOutlineDeleteForever
              className="expense-item-delete-icon"
              onClick={onClickDeleteExpense}
            />
          )}
        </>
      ) : (
        <>
          <h4 className="me-2">{itemTitle}</h4>
          <p>{itemData}</p>
        </>
      )}
    </div>
  );
};

export default ItemDetails;
