import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const DateFormatter = (dateContent: string) => {
  const date = new Date(dateContent);
  const formatedDate = format(date, 'dd/MM/YYY');

  return formatedDate;
};

export const HourFormatter = (dateContent: string) => {
  const date = new Date(dateContent);
  const originZone = 'Europe/Lisbon';
  const destinyZone = 'America/Sao_Paulo';

  const localDate = utcToZonedTime(date, destinyZone, {
    timeZone: originZone,
  });

  const formatedHour = format(localDate, 'HH:mm');

  return `${formatedHour} h`;
};

export const RideDateFormatter = (date: string) => {
  const hourDate = new Date(date);

  const day = hourDate.getUTCDate().toString().padStart(2, '0');
  const month = (hourDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = hourDate.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};
