import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';

import './styles.css';

type Props = {
  text: string;
  onClick?: () => void;
};

const ButtonIcon = ({ text, onClick }: Props) => {
  return (
    <div className="btn-container">
      <button className="btn btn-terciary" onClick={onClick}>
        <h6>{text}</h6>
      </button>
      <div className="btn-icon-container">
        <ArrowIcon />
      </div>
    </div>
  );
};

export default ButtonIcon;
