import { Switch } from 'react-router-dom';

import Navbar from './Navbar';

import AdminRoutes from './Routes/AdminRoutes';
import DriverRoutes from './Routes/DriverRoutes';
import ManagerRoutes from './Routes/ManagerRoutes';
import OperatorRoutes from './Routes/OperatorRoutes';
import PassengerRoutes from './Routes/PassengerRoutes';

import { hasAnyRoles } from 'utils/requests/auth/auth';

import './styles.css';

const MyApp = () => {
  return (
    <div className="my-app-container">
      <Navbar />

      <div className="my-app-content">
        <Switch>
          {hasAnyRoles(['ROLE_PASSENGER']) && <PassengerRoutes />}

          {hasAnyRoles(['ROLE_ADMIN']) && <AdminRoutes />}

          {hasAnyRoles(['ROLE_COMPANY']) && <ManagerRoutes />}

          {hasAnyRoles(['ROLE_DRIVER']) && <DriverRoutes />}

          {hasAnyRoles(['ROLE_OPERATOR']) && <OperatorRoutes />}
        </Switch>
      </div>
    </div>
  );
};

export default MyApp;
