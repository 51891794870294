import { NavLink } from 'react-router-dom';

import { BiDollar } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { BsBuilding } from 'react-icons/bs';
import { AiOutlineCar } from 'react-icons/ai';
import { MdPendingActions, MdHistory } from 'react-icons/md';

const AdminNavBar = () => {
  return (
    <>
      <NavLink
        to="/myApp/menu/admin/pending-rides"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdPendingActions />
        <h3 className="my-app-nav-option">Corridas pendentes</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/ride-history"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdHistory />
        <h3 className="my-app-nav-option">Histórico de corridas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-drivers"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <AiOutlineCar />
        <h3 className="my-app-nav-option">Motoristas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-companies"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BsBuilding />
        <h3 className="my-app-nav-option">Empresas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-operators"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <CgProfile />
        <h3 className="my-app-nav-option">Operadores</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/invoice"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BiDollar />
        <h3 className="my-app-nav-option">Fechamentos</h3>
      </NavLink>
    </>
  );
};

export default AdminNavBar;
