import './styles.css';

type Props = {
  text: string;
  onClick?: () => void;
  className: string;
};

const ButtonRegular = ({ text, onClick, className }: Props) => {
  return (
    <div className="regular-btn-container">
      <button className={className} onClick={onClick} type="button">
        {text}
      </button>
    </div>
  );
};

export default ButtonRegular;
