import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';

import ListItem from 'components/UserCard';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { requestBackEndAllCompanies } from 'utils/requests/company/companyRequests';
import { CompanyDataResponse } from 'utils/requests/company/companyRequests.types';

const Companies = () => {
  const [companies, setCompanies] = useState<CompanyDataResponse[]>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    requestBackEndAllCompanies()
      .then((response) => {
        setCompanies(response.data.content);
        setIsloading(false);
      })
      .catch(() => {
        Swal.fire({ title: 'Erro ao buscar empresas', icon: 'error' });
      })
      .finally(() => {
        setIsloading(false);
      });
  }, []);

  return (
    <div>
      <h2 className="page-subtitle">Consultar fechamento por empresa</h2>

      {isLoading ? (
        <ThreeDotsLoader />
      ) : companies?.length ? (
        <>
          <div className="mt-3">
            {companies.map((company) => (
              <Link
                to={`/myApp/menu/admin/company-invoice/${company.id}/${company.name}`}
                key={company.id}
              >
                <ListItem key={company.id} firstName={company.name} />
              </Link>
            ))}
          </div>
        </>
      ) : (
        <h3 className="page-subtitle mt-3 mb-3 text-secondary">
          Ainda não há empresa parceira cadastrada.
        </h3>
      )}
    </div>
  );
};

export default Companies;
