import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

import { CompanyUser } from 'utils/requests/company/companyRequests.types';
import {
  requestBackEndDeleteUser,
  requestBackEndUserById,
} from 'utils/requests/user/userRequests';
import { formatterUserRoles } from 'utils/formatters/formatterUser';
import history from 'utils/history';

import ButtonRegular from 'components/Buttons/ButtonRegular';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import './styles.css';

type UrlParams = {
  userId: string;
};

const MyUserDetails = () => {
  const { userId } = useParams<UrlParams>();
  const [user, setUser] = useState<CompanyUser>();
  const [formattedUserRole, setFormattedUserRole] = useState<string[]>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    requestBackEndUserById(userId)
      .then((response) => {
        setUser(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    if (user?.roles) {
      setFormattedUserRole(formatterUserRoles(user!.roles));
    }
  }, [user]);

  const handleDeleteUser = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: `Deletar ${user?.firstName} ${user?.lastName}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        requestBackEndDeleteUser(userId)
          .then(() => {
            Swal.fire('Deletado!', 'O usuário foi deletado.', 'success');
            history.push('/myapp/menu/company/my-users');
          })
          .catch(() => {
            Swal.fire('Oops!', 'Erro ao deletar usuário', 'error');
          });
      }
    });
  };

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        {isLoading ? (
          <ThreeDotsLoader />
        ) : (
          <>
            <div className="user-details-name pb-2">
              <h1 className="me-2 me-lg-3">{user?.firstName}</h1>
              <h1>{user?.lastName}</h1>
            </div>

            <h2 className="mt-4">
              Email:
              <small className="text-muted"> {user?.email}</small>
            </h2>

            <h2 className="mt-4">
              Tipo de usuário:
              <small className="text-muted ms-2">
                {formattedUserRole && formattedUserRole[0]}
              </small>
            </h2>

            <div className="user-details-btn-container mt-4">
              <ButtonRegular
                text="Deletar"
                className="btn btn-danger me-2"
                onClick={handleDeleteUser}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyUserDetails;
