import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ItemDetails from 'components/ItemDetails';
import ProcessInvoice from 'components/ProcessInvoice';
import ButtonRegular from 'components/Buttons/ButtonRegular';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import {
  DateFormatter,
  HourFormatter,
  RideDateFormatter,
} from 'utils/formatters/formatterResponse';
import { requestGetInvoiceById } from 'utils/requests/invoice/invoiceRequests';
import { CreatedInvoiceResponse } from 'utils/requests/ride/rideRequests.types';
import { SpringPage } from 'utils/types/request.types';
import { hasAnyRoles } from 'utils/requests/auth/auth';
import { formatExpenseValue } from 'utils/formatters/formatterMoney';
import history from 'utils/history';

type UrlParams = {
  companyId: string;
  invoiceId: string;
};

const InvoiceById = () => {
  const MySwal = withReactContent(Swal);
  const { companyId, invoiceId } = useParams<UrlParams>();
  const [invoice, setInvoice] = useState<SpringPage<CreatedInvoiceResponse>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState('');

  const onClickProcessInvoice = () => {
    MySwal.fire({
      title: 'Processar fechamento',
      html: <ProcessInvoice invoiceId={Number(invoiceId)} />,
      showConfirmButton: false,
    });
  };

  const onClickRide = (rideId: number) => {
    history.push(`/myapp/menu/ride-history/${rideId}`);
  };

  const handleFilterRide = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredRides = invoice?.content[0].rides.filter((ride) => {
    const costCenter = ride.costCenter;
    return costCenter.includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    requestGetInvoiceById(companyId, invoiceId)
      .then((response) => {
        setInvoice(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops',
          text: 'Ocorreu um erro ao trazer as informações',
          icon: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId, invoiceId]);

  return (
    <div className="base-card  p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Fechamento - {invoiceId}</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : (
          <>
            {hasAnyRoles(['ROLE_ADMIN']) && (
              <>
                <h2 className="page-subtitle mt-5">
                  Dados da Empresa parceira
                </h2>
                <ItemDetails
                  itemTitle="Nome"
                  itemData={invoice?.content[0].company.name}
                />
                <ItemDetails
                  itemTitle="Telefone"
                  itemData={invoice?.content[0].company.celphone}
                />
                <ItemDetails
                  itemTitle="Preço por Km"
                  itemData={invoice?.content[0].company.kmPrice}
                />
              </>
            )}

            <h2 className="page-subtitle mt-5">Status do fechamento</h2>
            {invoice?.content[0].status.map((invoiceStatus) => (
              <ItemDetails
                key={invoiceStatus.id}
                itemTitle={`${DateFormatter(
                  invoiceStatus.date,
                )} - ${HourFormatter(invoiceStatus.date)}`}
                itemData={invoiceStatus.status}
              />
            ))}

            <h2 className="page-subtitle mt-5">Valor fechamento</h2>
            <ItemDetails
              itemTitle="Despesas"
              itemData={formatExpenseValue(invoice!.content[0].totalExpenses)}
            />
            <ItemDetails
              itemTitle="Total do custo por Km"
              itemData={formatExpenseValue(invoice!.content[0].totalRides)}
            />

            <h2 className="page-subtitle mt-5">Corridas para fechamento</h2>
            {invoice?.content[0].rides.length ? (
              <>
                <div className="input-group flex-nowrap mb-3 mt-4">
                  <span className="input-group-text" id="addon-wrapping">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Centro de custo"
                    aria-label="costCenter"
                    aria-describedby="addon-wrapping"
                    onChange={handleFilterRide}
                  />
                </div>

                <div className="overflow">
                  {filteredRides?.length ? (
                    <table className="table-container">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>ORIGEM</th>
                          <th>DESTINO</th>
                          <th>DATA</th>
                          <th>C/ CUSTO</th>
                          <th>VALOR DESPESAS</th>
                          <th>VALOR/Km</th>
                          <th>VALOR TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredRides?.map((ride) => (
                          <tr
                            key={ride.id}
                            className="table-animation-effect"
                            onClick={() => onClickRide(ride.id!)}
                          >
                            <td>{`#${ride.id}`}</td>
                            <td>{ride.originCity}</td>
                            <td>{ride.destinyCity}</td>
                            <td>{RideDateFormatter(ride.scheduleDate)}</td>
                            <td>{ride.costCenter}</td>
                            <td>{formatExpenseValue(ride.totalExpenses)}</td>
                            <td>{formatExpenseValue(ride.kmValue)}</td>
                            <td>{formatExpenseValue(ride.total)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="h5 mt-3">Centro de custo não encontrado</p>
                  )}
                </div>
              </>
            ) : (
              <p className="page-subtitle">Sem corridas</p>
            )}

            {hasAnyRoles(['ROLE_COMPANY']) && (
              <div className="text-end mt-4">
                <ButtonRegular
                  text="Processar fechamento"
                  className="btn btn-success me-2"
                  onClick={onClickProcessInvoice}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceById;
