import FaturationPendingRides from './components/FaturationPendingRides';

import './styles.css';

const DashboardAdmin = () => {
  return (
    <div className="dashboard-admin-container container p-4 mt-5">
      <FaturationPendingRides />
    </div>
  );
};

export default DashboardAdmin;
