export const formatExpenseTitle = (
  expenseTitle: string,
): string | undefined => {
  const expenseTypes: { [key: string]: string } = {
    HORA_PARADA: 'Hora parada',
    ALIMENTACAO: 'Alimentação',
    ESTACIONAMENTO: 'Estacionamento',
    PEDAGIO: 'Pedágio',
    HOTEL: 'Hotel',
    COMBUSTIVEL: 'Combustível',
    OUTRO: 'Outro',
  };

  const formattedExpenseTitle = expenseTypes[expenseTitle];

  return formattedExpenseTitle;
};
