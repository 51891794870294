import { getAuthData } from 'utils/storage';

import CurrentRide from 'components/CurrentRide';
import RideHistory from 'components/RideHistory';
import PrivateRoute from 'components/PrivateRoute';

import DashboardDriver from 'pages/Driver/DashBoardDriver';
import RegisterDocumentsDriver from 'pages/Driver/RegisterDocumentsDriver';
import RideDetails from 'components/RideHistory/RideDetails';

const DriverRoutes = () => {
  const hasDocuments = getAuthData().hasDriverDocument;

  return (
    <>
      {hasDocuments ? (
        <>
          <PrivateRoute
            path="/myapp/menu/driver/dashboard"
            roles={['ROLE_DRIVER']}
          >
            <DashboardDriver />
          </PrivateRoute>

          <PrivateRoute
            path="/myapp/menu/ride/current-ride"
            roles={['ROLE_DRIVER']}
          >
            <CurrentRide isDriverRide />
          </PrivateRoute>

          <PrivateRoute path="/myapp/menu/ride-history" roles={['ROLE_DRIVER']}>
            <RideHistory requestEntity={'DRIVER'} />
          </PrivateRoute>

          <PrivateRoute
            path="/myApp/menu/ride-history/:rideId"
            roles={['ROLE_DRIVER']}
          >
            <RideDetails />
          </PrivateRoute>

          <PrivateRoute
            path="/myapp/menu/driver/config"
            roles={['ROLE_DRIVER']}
          >
            <RegisterDocumentsDriver isEditing />
          </PrivateRoute>
        </>
      ) : (
        <PrivateRoute
          path="/myapp/menu/driver/dashboard"
          roles={['ROLE_DRIVER']}
        >
          <RegisterDocumentsDriver />
        </PrivateRoute>
      )}
    </>
  );
};

export default DriverRoutes;
