import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import { AuthContext } from 'contex/AuthContext';

import { redirectRoutes } from 'utils/redirectRoutes';
import { requestBackEndUserById } from 'utils/requests/user/userRequests';
import { getTokenData, requestBackendLogin } from 'utils/requests/auth/auth';
import { getAuthData, saveAuthData, saveCompanyIdData } from 'utils/storage';

import './styles.css';

type FormData = {
  username: string;
  password: string;
};

const Login = () => {
  const { setAuthContextData } = useContext(AuthContext);

  const [hasError, setHasError] = useState(false);
  const [hasUserId, setHasUserId] = useState(false);
  const [isLoging, setIsLoging] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    formData.username = formData.username.toLocaleLowerCase();

    setIsLoging(true);
    requestBackendLogin(formData)
      .then((response) => {
        saveAuthData(response.data);
        setHasError(false);
        setAuthContextData({
          authenticated: true,
          tokenData: getTokenData(),
        });
        setIsLoging(false);
        setHasUserId(true);
        redirectRoutes();
      })
      .catch((error) => {
        setHasError(true);
        setIsLoging(false);
      });
  };

  useEffect(() => {
    const userId = getAuthData().userId ? String(getAuthData().userId) : null;

    hasUserId &&
      userId &&
      requestBackEndUserById(userId).then((response) => {
        const hasCompanyId = response.data.company?.id;
        hasCompanyId && saveCompanyIdData(response.data.company.id);
      });
  }, [hasUserId]);

  return (
    <div className="base-card login-card">
      <h1>LOGIN</h1>
      {hasError && (
        <div className="alert alert-danger">Erro ao tentar efetuar o login</div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <input
            {...register('username', {
              required: 'Campo obrigatório',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email inválido',
              },
            })}
            type="text"
            className={`form-control base-input ${
              errors.username ? 'is-invalid' : ''
            }`}
            placeholder="Email"
            name="username"
          />
          <div className="invalid-feedback d-block">
            {errors.username?.message}
          </div>
        </div>
        <div className="mb-2">
          <input
            {...register('password', {
              required: 'Campo obrigatório',
            })}
            type="password"
            className={`form-control base-input ${
              errors.password ? 'is-invalid' : ''
            }`}
            placeholder="Password"
            name="password"
          />
          <div className="invalid-feedback d-block">
            {errors.password?.message}
          </div>
        </div>
        <div className="login-recover-password-container">
          <Link className="login-recover-password" to="/login/auth/recover">
            Esqueci minha senha
          </Link>
        </div>

        <div className="login-submit">
          {isLoging ? (
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              visible={true}
              wrapperClass="login-loading"
            />
          ) : (
            <ButtonIcon text="Fazer login" />
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
