import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import Swal from 'sweetalert2';

import { requestBackEndAllCompanies } from 'utils/requests/company/companyRequests';
import { CompanyDataResponse } from 'utils/requests/company/companyRequests.types';

import './styles.css';

export type InvoiceFilterData = {
  companyId: CompanyDataResponse | null;
  origincity: string | null;
  destinycity: string | null;
};

type Props = {
  onSubmitFilter: (data: InvoiceFilterData) => void;
};

const InvoiceFilter = ({ onSubmitFilter }: Props) => {
  const [companies, setCompanies] = useState<CompanyDataResponse[]>([]);

  const { register, handleSubmit, setValue, control } =
    useForm<InvoiceFilterData>();

  const onSubmit = (formData: InvoiceFilterData) => {
    var hasDestinyCity = formData.destinycity?.length;
    var hasOriginCity = formData.origincity?.length;

    const payload: InvoiceFilterData = {
      companyId: formData.companyId,
      destinycity: hasDestinyCity ? formData.destinycity : null,
      origincity: hasOriginCity ? formData.origincity : null,
    };

    onSubmitFilter(payload);
  };

  const handleChangeCategory = (value: CompanyDataResponse) => {
    setValue('companyId', value);
  };

  const handleFormClear = () => {
    setValue('origincity', null);
    setValue('destinycity', null);
    setValue('companyId', null);
  };

  useEffect(() => {
    requestBackEndAllCompanies()
      .then((response) => {
        setCompanies(response.data.content);
      })
      .catch(() => {
        Swal.fire({ title: 'Erro ao buscar empresas', icon: 'error' });
      });
  }, []);

  return (
    <div className="invoice-filter-container">
      <form onSubmit={handleSubmit(onSubmit)} className="invoice-filter-form">
        <div className="invoice-filter-city-container">
          <div className="invoice-filter-city">
            <input
              {...register('origincity')}
              type="text"
              className="form-control"
              placeholder="Cidade de origem"
              name="origincity"
            />
          </div>

          <div className="invoice-filter-city">
            <input
              {...register('destinycity')}
              type="text"
              className="form-control"
              placeholder="Cidade de destino"
              name="destinycity"
            />
          </div>

          <div className="invoice-filter-layout-container">
            <div className="invoice-filter-company-item">
              <div>
                <Controller
                  name="companyId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={companies}
                      isClearable
                      placeholder="Empresa"
                      onChange={(value) =>
                        handleChangeCategory(value as CompanyDataResponse)
                      }
                      getOptionLabel={(category: CompanyDataResponse) =>
                        category.name
                      }
                      getOptionValue={(category: CompanyDataResponse) =>
                        String(category.id)
                      }
                    />
                  )}
                />
              </div>
            </div>

            <div className="invoice-filter-btn-container">
              <button
                onClick={handleFormClear}
                className="btn btn-outline-secondary "
              >
                LIMPAR
              </button>

              <button type="submit" className="btn btn-secondary ">
                FILTRAR
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceFilter;
