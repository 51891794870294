import Companies from './Companies/Companies';
import SaveInvoice from './SaveInvoice';

const Invoice = () => {
  return (
    <div className="container">
      <div className="base-card p-4 mt-5">
        <SaveInvoice />
      </div>
      <div className="base-card p-4 mt-5 mb-5">
        <Companies />
      </div>
    </div>
  );
};

export default Invoice;
