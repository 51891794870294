import { Controller, useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';

import Swal from 'sweetalert2';

import { requestSetRideValueKm } from 'utils/requests/ride/rideRequests';
import { requestSaveDriverFee } from 'utils/requests/expenses/expensesRequest';
import { SaveDriverFeePayload } from 'utils/requests/expenses/expensesRequests.types';

import './styles.css';

type FormData = {
  fee: number;
};

type Props = {
  rideId: number;
  isQualityCost?: boolean;
};

const DriverFee = ({ rideId, isQualityCost }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    if (isQualityCost) {
      requestSetRideValueKm(rideId, formData.fee)
        .then(() => {
          Swal.close();
          Swal.fire(
            'Valot total da corrrida alterado com sucesso',
            '',
            'success',
          );
        })
        .catch((error) => {
          Swal.fire('Oops', `${error.response.data.message}`, 'error');
        });
    } else {
      const driverFeePayload: SaveDriverFeePayload = {
        fee: String(formData.fee).replace(',', '.'),
        ride: {
          id: String(rideId),
        },
      };

      requestSaveDriverFee(driverFeePayload)
        .then(() => {
          Swal.close();
          Swal.fire('Custo motorista inserido com sucesso', '', 'success');
        })
        .catch((error) => {
          Swal.fire('Oops', `${error.response.data.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <Controller
          name="fee"
          rules={{ required: 'Campo obrigatório' }}
          control={control}
          render={({ field }) => (
            <CurrencyInput
              placeholder="Custo R$"
              className={`form-control base-input ${
                errors.fee ? 'is-invalid' : ''
              }`}
              disableGroupSeparators={true}
              value={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
        <div className="text-start invalid-feedback d-block">
          {errors.fee?.message}
        </div>
      </div>

      <div className="ride-expense-btn-container">
        <button className="btn btn-terciary ride-expense-btn" type="submit">
          Salvar
        </button>
        <button
          className="btn btn-danger ride-expense-btn"
          type="button"
          onClick={() => Swal.close()}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default DriverFee;
