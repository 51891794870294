const tokenKey = 'authData';
const companyId = 'companyId';

type LoginResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  userFirstName: string;
  userId: number;
  hasDriverDocument: boolean;
};

export const saveAuthData = (obj: LoginResponse) => {
  localStorage.setItem(tokenKey, JSON.stringify(obj));
};

export const getAuthData = () => {
  const str = localStorage.getItem(tokenKey) ?? '{}';
  return JSON.parse(str) as LoginResponse;
};

export const removeAuthData = () => {
  localStorage.removeItem(tokenKey);
};

export const saveCompanyIdData = (companyIdNumber: number) => {
  localStorage.setItem(companyId, JSON.stringify(companyIdNumber));
};

export const getCompanyIdData = () => {
  const number = localStorage.getItem(companyId) ?? '{}';
  return JSON.parse(number) as number;
};
