type ListItemProps = {
  firstName: string;
  lastName?: string;
};

const ListItem = (props: ListItemProps) => {
  const { firstName, lastName } = props;

  return (
    <div className="animeted-list-item-container">
      <p className="animeted-list-item me-2">
        {lastName ? `${firstName} ${lastName}` : firstName}
      </p>
    </div>
  );
};

export default ListItem;
