import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';

import ListItem from 'components/UserCard';
import ButtonRegular from 'components/Buttons/ButtonRegular';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { getAuthData } from 'utils/storage';
import { CompanyUser } from 'utils/requests/company/companyRequests.types';
import { requestCompanyById } from 'utils/requests/company/companyRequests';

import './styles.css';

const MyUsers = () => {
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>();
  const [searchTerm, setSearchTerm] = useState('');
  const [logedUserId, setLogedUserId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLogedUserId(getAuthData().userId);
    setIsLoading(true);

    requestCompanyById()
      .then((response) => {
        setCompanyUsers(response.data.user);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFilterUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = companyUsers?.filter((user) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">
          Usuários
          <small className="text-muted ms-2">
            cadastrados na minha empresa
          </small>
        </h1>
        <h3 className="page-subtitle">
          Ao clicar em um usuário, você pode gerenciá-lo.
        </h3>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : (
          <>
            <div className="input-group flex-nowrap mb-3 mt-4">
              <span className="input-group-text" id="addon-wrapping">
                <AiOutlineUser />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Nome do usuário"
                aria-label="Username"
                aria-describedby="addon-wrapping"
                onChange={handleFilterUser}
              />
            </div>
            {filteredUsers?.length ? (
              filteredUsers.map((user) =>
                user.id !== logedUserId ? (
                  <Link
                    to={`/myapp/menu/company/my-users/${user.id}`}
                    key={user.id}
                  >
                    <ListItem
                      firstName={user.firstName}
                      lastName={user.lastName}
                    />
                  </Link>
                ) : (
                  <ListItem
                    key={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                  />
                )
              )
            ) : (
              <div className="d-flex justify-content-center mt-5">
                <p className="h4">Usuário não encontrado</p>
              </div>
            )}
          </>
        )}

        <div className="add-company-user mt-3">
          <Link to={'/myapp/menu/company/registeruser'}>
            <ButtonRegular text="Cadastrar" className="btn btn-success" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyUsers;
