import DashboardRequestRace from 'components/DashBoard/DashboardRequestRace';

import './styles.css';

const DashboardPassenger = () => {
  return (
    <div className="dashboard-passenger-container container">
      <DashboardRequestRace />

      <div className="dashboard-passenger-flex-container">
        <div className="dashboard-cancel-race secondary-base-card">
          <h1>Atenção!</h1>
          <p>
            Para realizar o cancelamento sua viagem, você deve fazer essa
            solicitação dentro do período de até
            <b> 1 hora antes do início da viagem.</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardPassenger;
