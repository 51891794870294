import ItemDetails from 'components/ItemDetails';

type Props = {
  passengerName: string;
  dateTime: string;
  originAdress: string;
  destinyAdress: string;
};

const RideDetails = (props: Props) => {
  const { dateTime, destinyAdress, originAdress, passengerName } = props;

  return (
    <div className="base-card p-3">
      <ItemDetails itemTitle="Nome do passageiro" itemData={passengerName} />
      <ItemDetails itemTitle="Data da solicitação" itemData={dateTime} />
      <ItemDetails itemTitle="Endereço de origem" itemData={originAdress} />
      <ItemDetails itemTitle="Endereço de destino" itemData={destinyAdress} />
    </div>
  );
};

export default RideDetails;
