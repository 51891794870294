import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ReactComponent as AuthImage } from 'assets/images/auth_image.svg';

import Login from './Login';

import { redirectRoutes } from 'utils/redirectRoutes';
import { isAuthenticated } from 'utils/requests/auth/auth';

import './styles.css';

const Auth = () => {
  useEffect(() => {
    if (isAuthenticated()) {
      redirectRoutes();
    }
  }, []);

  return (
    <div className="login-container">
      <div className="login-banner-container">
        <h2>Seja bem-vindo ao Quality Logística </h2>
        <p>
          Na Quality você pode solicitar seu veículo com mais rapidez, segurança
          e praticidade.
        </p>
        <AuthImage />
      </div>

      <div className="login-form-container">
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Auth;
