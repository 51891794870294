import {useState} from 'react';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';

import ItemDetails from 'components/ItemDetails';

import {getAuthData} from 'utils/storage';
import {RideDateFormatter} from 'utils/formatters/formatterResponse';
import {
    DriverAcceptanceRequest,
    FindPendingRideByDriverResponse,
} from 'utils/requests/fowarding/fowardingRequests.types';

import {SwalRideAcceptance} from './helpers';

import './styles.css';

type Props = {
    requestedRides: FindPendingRideByDriverResponse[];
};

const CurrentDriverRide = ({requestedRides}: Props) => {
    const [rideDetails, setRideDetails] =
        useState<FindPendingRideByDriverResponse | null>();
    const driverId = getAuthData().userId;

    const onOpenDetails = (ride: FindPendingRideByDriverResponse) => {
        setRideDetails(ride);
    };

    const onCloseDetails = () => {
        setRideDetails(null);
    };

    const handleDriverAcceptance = (accepted: boolean) => {
        if (rideDetails?.ride.id) {
            const rideId = rideDetails?.ride.id;

            const requestData: DriverAcceptanceRequest = {
                driver: {
                    driver: {
                        id: driverId,
                    },
                },
                ride: {
                    id: Number(rideId),
                },
                accepted,
            };

            SwalRideAcceptance({accepted, requestData});
        }
    };

    return rideDetails ? (
        <div className="transition-open base-card p-3 ">
            <div className="text-end">
                <IoIosArrowUp
                    className="current-driver-ride-arrow-icon"
                    onClick={onCloseDetails}
                />
            </div>

            <ItemDetails
                itemData={rideDetails.ride.applicantName}
                itemTitle="Nome do solicitante"
            />
            <ItemDetails
                itemData={rideDetails.ride.applicantEmail}
                itemTitle="Email do solicitante"
            />
            <ItemDetails itemData={rideDetails.ride.contact} itemTitle="Contato"/>
            <ItemDetails
                itemData={rideDetails.ride.costCenter}
                itemTitle="Centro de custo"
            />

            <ItemDetails
                itemData={rideDetails.ride.originAddress}
                itemTitle="Endereço de origem"
            />

            <ItemDetails
                itemData={rideDetails.ride.destinyAddress}
                itemTitle="Endereço de destino"
            />

            <ItemDetails
                itemData={RideDateFormatter(rideDetails.ride.scheduleDate)}
                itemTitle="Data da solicitação"
            />
            <ItemDetails
                itemData={rideDetails.ride.scheduleTime}
                itemTitle="Hora da solicitação"
            />
            <ItemDetails
                itemData={rideDetails.ride.observations}
                itemTitle="Observação"
            />

            <div className="current-driver-ride-btn-container">
                <button
                    type="button"
                    onClick={() => handleDriverAcceptance(true)}
                    className="btn btn-success"
                >
                    Aceitar corrida
                </button>
                <button
                    type="button"
                    onClick={() => handleDriverAcceptance(false)}
                    className="btn btn-danger"
                >
                    Rejeitar corrida
                </button>
            </div>
        </div>
    ) : (
        <div className="transition-close">
            <div className="overflow">
                <table className="table-container">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>ORIGEM</th>
                        <th>DESTINO</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {requestedRides.map((ride) => (
                        <tr key={ride.ride.id} className="table-animation-effect">
                            <td>{ride.ride.id}</td>
                            <td>{ride.ride.originCity}</td>
                            <td>{ride.ride.destinyCity}</td>
                            <td>
                                <IoIosArrowDown
                                    className="current-driver-ride-arrow-icon"
                                    onClick={() => onOpenDetails(ride)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CurrentDriverRide;
