import { NavLink } from 'react-router-dom';

import { BiCar } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { MdHistory } from 'react-icons/md';
import { IoSettingsOutline, IoMapOutline } from 'react-icons/io5';

const ManagerNavBar = () => {
  return (
    <>
      <NavLink
        to="/myapp/menu/company/request-ride"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BiCar />
        <h3 className="my-app-nav-option">Solicitar corrida</h3>
      </NavLink>

      <NavLink
        to="/myapp/menu/manager/ride/current-ride"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <IoMapOutline />
        <h3 className="my-app-nav-option">Corria atual</h3>
      </NavLink>

      <NavLink
        to="/myapp/menu/ride-history"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdHistory />
        <h3 className="my-app-nav-option">Histórico de corridas</h3>
      </NavLink>

      <NavLink
        to="/myapp/menu/company/my-users"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BsPeople />
        <h3 className="my-app-nav-option">Meus usuários</h3>
      </NavLink>

      <NavLink
        to="/myapp/menu/company/config"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <IoSettingsOutline />
        <h3 className="my-app-nav-option">Configurar conta</h3>
      </NavLink>
    </>
  );
};

export default ManagerNavBar;
