import { FiShield } from 'react-icons/fi';
import { BsCheck2Circle } from 'react-icons/bs';
import { RiComputerLine } from 'react-icons/ri';

import './styles.css';

type CardProps = {
  id: number;
  name: string;
  description: string;
};

const Card = (props: CardProps) => {
  const { name, description, id } = props;

  return (
    <div className="card-container">
      <div className="card-icon-container">
        <i>{id === 1 && <BsCheck2Circle />}</i>
        <i>{id === 2 && <FiShield />}</i>
        <i>{id === 3 && <RiComputerLine />}</i>
      </div>

      <div className="card-text-container">
        <h1>{name}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Card;
