import axios from 'axios';

import { BASE_URL } from 'utils/requests/requests';
import { getAuthData } from 'utils/storage';
import { SingupData } from 'utils/types/request.types';
import { CompanyUser } from '../company/companyRequests.types';

export const requestBackEndUserById = (userId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/users/${userId}`,
    headers,
  });
};

export const requestBackEndDeleteUser = (userId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'DELETE',
    baseURL: BASE_URL,
    url: `/users/${userId}`,
    headers,
  });
};

export const requestBackEndDeleteManager = (userId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'DELETE',
    baseURL: BASE_URL,
    url: `/users/delete/${userId}`,
    headers,
  });
};

export const requestBackendSingup = (singupData: SingupData) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  const data = JSON.stringify(singupData);

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/users',
    data,
    headers,
  });
};

export const requestBackEndUpdateUser = (user: CompanyUser) => {
  const token = getAuthData().access_token;
  const userId = user.id;

  const data = JSON.stringify(user);

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `/users/${userId}`,
    data,
    headers,
  });
};
