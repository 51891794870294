import VehiclesCarousel from './VehiclesCarousel';

import './styles.css';

const VehiclesCategory = () => {
  return (
    <div className="vehicles-category-container">
      <h1 className="vehicles-category-title">Categoria dos veículos</h1>

      <VehiclesCarousel />
    </div>
  );
};

export default VehiclesCategory;
