import RequestRace from 'components/RequestRide';
import RideHistory from 'components/RideHistory';
import CurrentRide from 'components/CurrentRide';
import InvoiceById from 'components/InvoiceById';
import PrivateRoute from 'components/PrivateRoute';
import UpdateAccount from 'components/UpdateAccount';
import RideDetails from 'components/RideHistory/RideDetails';

import MyUsers from 'pages/Company/MyUsers';
import Singup from 'pages/MyApp/Auth/Singup';
import MyUserDetails from 'pages/Company/MyUserDetails';
import ManagerDashboard from 'pages/Company/ManagerDashboard';

const ManagerRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/myapp/menu/company/manager-dashboard"
        roles={['ROLE_COMPANY']}
      >
        <ManagerDashboard />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/company/request-ride"
        roles={['ROLE_COMPANY']}
      >
        <RequestRace isManagerRequest />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/manager/ride/current-ride"
        roles={['ROLE_COMPANY']}
      >
        <CurrentRide />
      </PrivateRoute>

      <PrivateRoute path="/myapp/menu/ride-history" roles={['ROLE_COMPANY']}>
        <RideHistory requestEntity={'MANAGER'} />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/ride-history/:rideId"
        roles={['ROLE_COMPANY']}
      >
        <RideDetails />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/company/my-users"
        roles={['ROLE_COMPANY']}
      >
        <MyUsers />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/company/registeruser"
        roles={['ROLE_COMPANY']}
      >
        <Singup isCompanySingUp requestEntity="PASSENGER" />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/company/my-users/:userId"
        roles={['ROLE_COMPANY']}
      >
        <MyUserDetails />
      </PrivateRoute>

      <PrivateRoute path="/myapp/menu/company/config" roles={['ROLE_COMPANY']}>
        <UpdateAccount />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/manager/company-invoice-by-id/:companyId/:invoiceId"
        roles={['ROLE_COMPANY']}
      >
        <InvoiceById />
      </PrivateRoute>
    </>
  );
};

export default ManagerRoutes;
