import history from 'utils/history';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import { ReactComponent as RequestRideImg } from 'assets/images/order_ride.svg';

import './styles.css';

const DashboardRequestRace = () => {
  const handleOnClickRequestRace = () => {
    history.push('/myapp/menu/passenger/requestrace');
  };

  return (
    <div className="request-race-container secondary-base-card">
      <div className="request-race-img-container ">
        <RequestRideImg />
      </div>

      <h1 className="mb-2">Não fique na mão</h1>

      <p className="mb-4">
        Na Quality, além de contar com os melhores veículos, temos sempre
        motoristas à sua disposição, sempre que você precisar estaremos prontos
        para te atender e lhe prestar o melhor serviço, não perca tempo ou seus
        compromissos importantes, solicite um veículo no botão abaixo.
      </p>
      <ButtonIcon
        onClick={handleOnClickRequestRace}
        text="Solicitar corrida"
      ></ButtonIcon>
    </div>
  );
};

export default DashboardRequestRace;
