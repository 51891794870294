import RaceDetails from 'components/RideHistory/RideDetails';
import AssignDriver from '../AssignDriver';

const PendingRideDetails = () => {
  return (
    <div className="pending-ride-details-container">
      <AssignDriver />
      <RaceDetails />
    </div>
  );
};

export default PendingRideDetails;
