import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { getCompanyIdData } from 'utils/storage';
import { DateFormatter } from 'utils/formatters/formatterResponse';
import { requestGetInvoices } from 'utils/requests/invoice/invoiceRequests';

import { SpringPage } from 'utils/types/request.types';
import { CreatedInvoiceResponse } from 'utils/requests/ride/rideRequests.types';

type ControlComponentsData = {
  activePage: number;
};

const ManagerInvoice = () => {
  const companyId = getCompanyIdData();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invoices, setInvoices] =
    useState<SpringPage<CreatedInvoiceResponse>>();
  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({
      activePage: 0,
    });

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getInvoices = useCallback(() => {
    requestGetInvoices(String(companyId), controlComponentsData.activePage)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops',
          text: 'Erro ao trazer fechamentos',
          icon: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId, controlComponentsData.activePage]);

  const onClickInvoice = (invoiceId: number) => {
    history.push(
      `/myapp/menu/manager/company-invoice-by-id/${companyId}/${invoiceId}`,
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getInvoices();
  }, [getInvoices]);

  return (
    <div>
      <div className="base-card p-5 mt-5 container">
        <h1 className="page-title">Fechamentos</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : invoices?.content.length ? (
          <>
            <h2 className="page-subtitle">Clique e vá para os detalhes</h2>
            <div className="overflow">
              <table className="table-container">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>STATUS</th>
                    <th>DATA</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.content.map((invoice) => (
                    <tr
                      key={invoice.id}
                      className="table-animation-effect"
                      onClick={() => onClickInvoice(invoice.id)}
                    >
                      <td>{`#${invoice.id}`}</td>
                      <td>
                        {invoice.status[invoice.status.length - 1].status}
                      </td>
                      <td>{DateFormatter(invoice.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <h2 className="page-subtitle">Ainda não há fechamentos</h2>
        )}

        {invoices && invoices?.content.length > 0 && (
          <div className="mt-5">
            <Pagination
              pageCount={invoices ? invoices.totalPages : 0}
              pageRange={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagerInvoice;
