import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { findPendingRideFaturation } from 'utils/requests/ride/rideRequests';

const FaturationPendingRides = () => {
  const [pendingRideQuantity, setPendingRideQuantity] = useState<number>();

  useEffect(() => {
    findPendingRideFaturation()
      .then((response) => {
        const responseData: number = response.data.content.length;
        setPendingRideQuantity(responseData);
      })
      .catch(() => {
        Swal.fire('Oops', 'Houve um problema', 'error');
      });
  }, []);

  return (
    <Link to={'/myApp/menu/dashboard/faturation-rides'}>
      <div className="dashboard-admin-pending-rides base-card">
        <p className="dashboard-admin-pending-rides-text">
          Quantidade de corridas pendentes de faturamento
        </p>
        <span className="dashboard-admin-pending-rides-quantity">
          {pendingRideQuantity}
        </span>
      </div>
    </Link>
  );
};

export default FaturationPendingRides;
