// COMPONENTS
import PendingRidesToAccept from './components/pendingRidesToAccept';
import PendingRidesToStart from './components/pendingRidesToStart';

const DashboardDriver = () => {
  return (
    <div className="container">
      <PendingRidesToAccept />
      <PendingRidesToStart />
    </div>
  );
};

export default DashboardDriver;
