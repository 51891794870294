import axios from 'axios';

import { getAuthData } from 'utils/storage';

import { BASE_URL } from '../requests';

import { DriverAcceptanceRequest } from './fowardingRequests.types';

export const requestFindPendingRideByDriver = () => {
  const token = getAuthData().access_token;
  const driverId = getAuthData().userId;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/fowards/driver/${driverId}/pending`,
    headers,
  });
};

export const requestSendDriverRideAcceptance = (
  requestData: DriverAcceptanceRequest,
) => {
  const data = JSON.stringify(requestData);
  const token = getAuthData().access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/fowards/driver/response',
    data,
    headers,
  });
};
