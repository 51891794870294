import { Redirect, Route, Router, Switch } from 'react-router-dom';

import Home from 'pages/Home';
import Auth from 'pages/MyApp/Auth';
import MyApp from 'pages/MyApp';
import LoginRedirect from 'pages/LoginRedirect';
import RecoverPassword from 'pages/MyApp/Auth/RecoverPassword';
import RecoverPasswordForm from 'pages/MyApp/Auth/RecoverPasswordForm';

import Navbar from 'components/NavBar';

import history from 'utils/history';

const Routes = () => (
  <Router history={history}>
    <Navbar />
    <Switch>
      <Route path="/home">
        <Home />
      </Route>

      <Route path="/login/auth/recover">
        <RecoverPassword />
      </Route>

      <Route path="/login/auth/password-recover">
        <RecoverPasswordForm />
      </Route>

      <Redirect from="/" to={'/login/auth/enter'} exact />
      <Redirect from="/login" to={'/login/auth/enter'} exact />
      <Route path="/login">
        <Auth />
      </Route>

      <Route path="/myApp">
        <MyApp />
      </Route>

      <Route path={'/redirectToLogin'}>
        <LoginRedirect />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
