import { useEffect, useMemo, useState } from 'react';
import { AllRidesResponse } from 'utils/requests/ride/rideRequests.types';
import { SpringPage } from 'utils/types/request.types';
import { getPendingRidesToAccept, getRidesAcceptedByDriver } from './utils';
import { FindPendingRideByDriverResponse } from 'utils/requests/fowarding/fowardingRequests.types';

export function usePedingRidesToStart() {
  const [rides, setRides] = useState<SpringPage<AllRidesResponse>>();

  useEffect(() => {
    getRidesAcceptedByDriver(setRides);
  }, []);

  return useMemo(
    () => ({
      rides,
    }),
    [rides],
  );
}

export function usePendingRidesToAccept() {
  const [requestedRide, setRequestRide] = useState<
    FindPendingRideByDriverResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getPendingRidesToAccept(setRequestRide, setIsLoading);
  }, []);

  return useMemo(
    () => ({
      requestedRide,
      isLoading,
    }),
    [requestedRide, isLoading],
  );
}
