import RideDetails from './RideDetails';
import DriverDetails from './DriverDetails';

import { DateFormatter } from 'utils/formatters/formatterResponse';
import { PendingRidesResponse } from 'utils/requests/ride/rideRequests.types';

import './styles.css';

type Props = {
  raceData: PendingRidesResponse;
  isDriverRide?: boolean;
};

const RideData = (props: Props) => {
  const { raceData, isDriverRide } = props;

  return (
    <div>
      <RideDetails
        dateTime={DateFormatter(raceData.ride.scheduleDate)}
        destinyAdress={raceData.ride.destinyAddress}
        originAdress={raceData.ride.originAddress}
        passengerName={`${
          raceData.passenger.firstName + ' ' + raceData.passenger.lastName
        }`}
      />

      {!isDriverRide && <DriverDetails carData={raceData.car} />}
    </div>
  );
};

export default RideData;
