import { NavLink } from 'react-router-dom';

import { MdPendingActions, MdHistory } from 'react-icons/md';

const OperatorNavBar = () => {
  return (
    <>
      <NavLink
        to="/myApp/menu/admin/pending-rides"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdPendingActions />
        <h3 className="my-app-nav-option">Corridas pendentes</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/ride-history"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdHistory />
        <h3 className="my-app-nav-option">Histórico de corridas</h3>
      </NavLink>
    </>
  );
};

export default OperatorNavBar;
