import history from 'utils/history';
import { hasAnyRoles } from 'utils/requests/auth/auth';

export const redirectRoutes = () => {
  if (hasAnyRoles(['ROLE_ADMIN', 'ROLE_OPERATOR'])) {
    history.push('/myApp/menu/dashboard');
  } else if (hasAnyRoles(['ROLE_COMPANY'])) {
    history.push('/myapp/menu/company/manager-dashboard');
  } else if (hasAnyRoles(['ROLE_DRIVER'])) {
    history.push('/myapp/menu/driver/dashboard');
  } else if (hasAnyRoles(['ROLE_PASSENGER'])) {
    history.push('/myapp/menu/passenger/dashboard');
  }
};
