import RideHistory from 'components/RideHistory';
import PrivateRoute from 'components/PrivateRoute';
import RideDetails from 'components/RideHistory/RideDetails';

import PendingRides from 'pages/Admin/PendingRides';
import DashboardOperator from 'pages/Operator/DashboardOperator';
import PendingRideDetails from 'pages/Admin/PendingRides/PendingRideDetails';

const OperatorRoutes = () => {
  return (
    <>
      <PrivateRoute path="/myApp/menu/dashboard" roles={['ROLE_OPERATOR']}>
        <DashboardOperator />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/admin/pending-rides">
        <PendingRides />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/pending-rides/:rideId"
        roles={['ROLE_OPERATOR']}
      >
        <PendingRideDetails />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/ride-history" roles={['ROLE_OPERATOR']}>
        <RideHistory requestEntity={'ADMIN'} />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/ride-history/:rideId"
        roles={['ROLE_OPERATOR']}
      >
        <RideDetails isFromAdmin />
      </PrivateRoute>
    </>
  );
};

export default OperatorRoutes;
