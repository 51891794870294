import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';

import Swal from 'sweetalert2';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import history from 'utils/history';
import { requestBackendChangePassword } from 'utils/requests/auth/auth';

import './styles.css';

type FormData = {
  email: string;
  code: string;
  password: string;
};

const RecoverPasswordForm = () => {
  const [isLoging, setIsLoging] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    setIsLoging(true);

    requestBackendChangePassword(
      formData.email,
      formData.password,
      formData.code,
    )
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Senha redefinida com sucesso',
          icon: 'success',
        }).then(() => {
          history.push('/login/auth/enter');
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: 'Oops...',
          text:
            error?.response?.data?.message ?? 'Falha em redefinir sua senha',
          icon: 'error',
        });
      })
      .finally(() => {
        setIsLoging(false);
      });
  };

  return (
    <div className="base-card recover-password-container">
      <div className="recover-password-content">
        <div className="recover-password-content-wrapper">
          <h2 className="recover-password-app-icon">Quality App</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="recover-password-field-label" htmlFor="email">
                Insira o email cadastrado
              </label>
              <input
                {...register('email', {
                  required: 'Campo obrigatório',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email inválido',
                  },
                })}
                autoComplete="off"
                type="text"
                className={`form-control base-input ${
                  errors.email ? 'is-invalid' : ''
                }`}
                placeholder="Email cadastrado"
                name="email"
              />
              <div className="invalid-feedback d-block">
                {errors.email?.message}
              </div>
            </div>

            <div className="mb-4">
              <label className="recover-password-field-label" htmlFor="email">
                Insira a nova senha
              </label>
              <input
                {...register('password', {
                  required: 'Campo obrigatório',
                })}
                autoComplete="off"
                type="password"
                className={`form-control base-input ${
                  errors.password ? 'is-invalid' : ''
                }`}
                placeholder="Nova senha"
                name="password"
              />
              <div className="invalid-feedback d-block">
                {errors.password?.message}
              </div>
            </div>

            <div className="mb-4">
              <label className="recover-password-field-label" htmlFor="email">
                Insira o código recebido
              </label>
              <input
                {...register('code', {
                  required: 'Campo obrigatório',
                })}
                type="text"
                className={`form-control base-input ${
                  errors.password ? 'is-invalid' : ''
                }`}
                placeholder="Código"
                name="code"
                maxLength={5}
                minLength={5}
              />
              <div className="invalid-feedback d-block">
                {errors.code?.message}
              </div>
            </div>

            <div className="login-submit">
              {isLoging ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  visible={true}
                  wrapperClass="login-loading"
                />
              ) : (
                <ButtonIcon text="Alterar senha" />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoverPasswordForm;
