import axios from 'axios';

import { BASE_URL } from 'utils/requests/requests';
import { getAuthData, getCompanyIdData } from 'utils/storage';

import { RequestBackendRaceData } from './rideRequests.types';

import { EntitiesType, RideId } from 'utils/types/entities.types';

export const requestBrazilianStates = () => {
  return axios({
    method: "GET",
    baseURL: "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
  })
}

export const requestBrazilianCities = (stateID: string) => {
  return axios({
    method: "GET",
    baseURL: `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateID}/municipios`
  })
}

export const requestBackendRide = (raceData: RequestBackendRaceData) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  const data = JSON.stringify(raceData);

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/rides',
    data,
    headers,
  });
};

export const requestBackendRidesByEntity = (
  activePage: number,
  requestEntity: EntitiesType,
) => {
  const companyId = getCompanyIdData();
  const driverId = getAuthData().userId;
  const token = getAuthData().access_token;
  const passengerId = getAuthData().userId;

  const entities = {
    PASSENGER: `passenger/${passengerId}`,
    ADMIN: '',
    MANAGER: `company/${companyId}`,
    DRIVER: `driver/${driverId}`,
    OPERATOR: ``,
  };

  const requestType = entities[requestEntity];

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/${requestType}`,
    headers,
    params: {
      page: activePage,
      size: 10,
    },
  });
};

export const requestBackendRaceById = (raceId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/${raceId}`,
    headers,
  });
};

export const requestFindHappeningRideByDriverId = () => {
  const token = getAuthData().access_token;
  const driverId = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/driver/${driverId}/happening`,
    headers,
  });
};

export const requestFindHappeningRideByPassengerId = () => {
  const token = getAuthData().access_token;
  const passengerId = getAuthData().userId;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/passenger/${passengerId}/happening`,
    headers,
  });
};

export const requestFinishRideRide = (rideId: number, finishType: number) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: `/rides/${rideId}/finish/${finishType}`,
    headers,
  });
};

export const findPendingRideFaturation = () => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'GET',
    baseURL: BASE_URL,
    url: `/rides/pending-fatured`,
    headers,
  });
};

export const setHappeningRide = (rideId: number) => {
  const token = getAuthData().access_token;

  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `/rides/${rideId}/happening`,
    headers,
  });
};

export const requestSetRideKm = (rideId: number, rideKm: number) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `/rides/${rideId}/km`,
    headers,
    params: {
      km: rideKm,
    },
  });
};

export const requestSetRideValues = (rideIds: RideId[], value: number) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: 'rides/values',
    headers,
    params: {
      value: value,
    },
    data: rideIds,
  });
};

export const requestSetRideValuesKm = (rideIds: RideId[], value: number) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: 'rides/kms',
    headers,
    params: {
      km: value,
    },
    data: rideIds,
  });
};

export const requestSetRideValueKm = (rideId: number, value: number) => {
  const token = getAuthData().access_token;
  const headers = {
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `rides/${rideId}/value`,
    headers,
    params: {
      total: value,
    },
  });
};
