import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';

import Swal from 'sweetalert2';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import history from 'utils/history';
import { requestBackendRecoverPasswordCode } from 'utils/requests/auth/auth';

import './styles.css';

type FormData = {
  email: string;
};

const RecoverPassword = () => {
  const [isLoging, setIsLoging] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    setIsLoging(true);

    requestBackendRecoverPasswordCode(formData.email)
      .then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Você receberá em seu email um código para recuperação de sua senha',
          icon: 'success',
        }).then(() => {
          history.push('/login/auth/enter');
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops...',
          text: 'Falha em envio do código de recuperação de senha',
          icon: 'error',
        });
      })
      .finally(() => {
        setIsLoging(false);
      });
  };

  return (
    <div className="base-card recover-password-container">
      <div className="recover-password-content">
        <div className="recover-password-content-wrapper">
          <h2 className="recover-password-app-icon">Quality App</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="recover-password-field-label" htmlFor="email">
                Insira o email cadastrado
              </label>
              <input
                {...register('email', {
                  required: 'Campo obrigatório',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email inválido',
                  },
                })}
                type="text"
                className={`form-control base-input ${
                  errors.email ? 'is-invalid' : ''
                }`}
                placeholder="Email cadastrado"
                name="email"
              />
              <div className="invalid-feedback d-block">
                {errors.email?.message}
              </div>
            </div>

            <div className="login-submit">
              {isLoging ? (
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  visible={true}
                  wrapperClass="login-loading"
                />
              ) : (
                <ButtonIcon text="Solicitar código" />
              )}
            </div>
          </form>

          <div className="recover-password-separator-container">
            <hr />
            <span>OU</span>
            <hr />
          </div>

          <div className="recover-password-login-container">
            <p>Lembrou sua senha?</p>
            <Link to={'/login/auth/enter'}>Faça login aqui</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
