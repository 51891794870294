import CurrentRide from 'components/CurrentRide';
import RequestRace from 'components/RequestRide';
import RideHistory from 'components/RideHistory';
import PrivateRoute from 'components/PrivateRoute';
import RideDetails from 'components/RideHistory/RideDetails';

import DashboardPassenger from 'pages/Passenger/DashBoardPassenger';
import UpdateAccount from 'components/UpdateAccount';

const PassengerRoutes = () => {
  return (
    <>
      <PrivateRoute
        path="/myapp/menu/passenger/dashboard"
        roles={['ROLE_PASSENGER']}
      >
        <DashboardPassenger />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/passenger/requestrace"
        roles={['ROLE_PASSENGER']}
      >
        <RequestRace />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/passenger/ride/current-ride"
        roles={['ROLE_PASSENGER']}
      >
        <CurrentRide />
      </PrivateRoute>

      <PrivateRoute path="/myapp/menu/ride-history" roles={['ROLE_PASSENGER']}>
        <RideHistory requestEntity={'PASSENGER'} />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/ride-history/:rideId"
        roles={['ROLE_PASSENGER']}
      >
        <RideDetails />
      </PrivateRoute>

      <PrivateRoute
        path="/myapp/menu/passenger/config"
        roles={['ROLE_PASSENGER']}
      >
        <UpdateAccount />
      </PrivateRoute>
    </>
  );
};

export default PassengerRoutes;
