import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AxiosRequestConfig } from 'axios';

import Pagination from 'components/Pagination';

import { SpringPage } from 'utils/types/request.types';
import { requestBackend } from 'utils/requests/requests';
import { ControlComponentsData } from 'utils/types/entities.types';
import { PendingRidesResponse } from 'utils/requests/ride/rideRequests.types';

import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';
import { RideDateFormatter } from 'utils/formatters/formatterResponse';

const PendingRides = () => {
  const history = useHistory();
  const [page, setPage] = useState<SpringPage<PendingRidesResponse>>();
  const [isLoading, setIsloading] = useState<boolean>(true);

  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({
      activePage: 0,
    });

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getPendingRides = useCallback(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/rides/pending`,
      params: {
        page: controlComponentsData.activePage,
        size: 10,
      },
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setPage(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
      });
  }, [controlComponentsData]);

  const onClickRideItem = (rideId?: number) => {
    history.push(`/myApp/menu/admin/pending-rides/${rideId}`);
  };

  useEffect(() => {
    setIsloading(true);
    getPendingRides();
  }, [getPendingRides]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Corridas pendentes</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : page?.content.length ? (
          <>
            <h3 className="page-subtitle">
              Clique em uma corrida, e atribua um motorista.
            </h3>
            <div className="overflow">
              <table className="table-container">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>SOLICITANTE</th>
                    <th>EMPRESA</th>
                    <th>DATA</th>
                    <th>HORA</th>
                  </tr>
                </thead>
                <tbody>
                  {page.content.map((ride) => (
                    <tr
                      key={ride.ride.id}
                      className="table-animation-effect"
                      onClick={() => onClickRideItem(ride.ride.id)}
                    >
                      <td>{`#${ride.ride.id}`}</td>
                      <td>{ride.ride.applicantName}</td>
                      <td>{ride.company.name}</td>
                      <td>{RideDateFormatter(ride.ride.scheduleDate)}</td>
                      <td>{ride.ride.scheduleTime}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <h3 className="page-subtitle mt-3 mb-3 text-secondary">
            Não há corridas pendentes
          </h3>
        )}

        {page && page?.content.length > 0 && (
          <div className="mt-5">
            <Pagination
              pageCount={page ? page.totalPages : 0}
              pageRange={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingRides;
