import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// THIRD PARTY LIBS
import Swal from 'sweetalert2';
import { AxiosRequestConfig } from 'axios';

// UTILS
import { Passenger } from 'utils/types/entities.types';
import { SpringPage } from 'utils/types/request.types';
import { requestBackend } from 'utils/requests/requests';

// COMPONENTS
import ListItem from 'components/UserCard';
import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

type ControlComponentsData = {
  activePage: number;
};

const Operator = () => {
  const [page, setPage] = useState<SpringPage<Passenger>>();
  const [isLoading, setIsloading] = useState<boolean>();

  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({
      activePage: 0,
    });

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getOperators = useCallback(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/users',
      params: {
        page: controlComponentsData.activePage,
        size: 10,
        role: 'ROLE_OPERATOR',
      },
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setPage(response.data);
        setIsloading(false);
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops',
          text: 'Ocorreu um erro',
          icon: 'error',
        });
      });
  }, [controlComponentsData]);

  useEffect(() => {
    setIsloading(true);
    getOperators();
  }, [getOperators]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Operadores</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : page?.content.length ? (
          <>
            <h2 className="page-subtitle">
              Ao clicar em um operador parceiro, você gerenciá-lo.
            </h2>
            <div className="mt-3 mb-5">
              {page.content.map((operatorData) => (
                <Link
                  key={operatorData.id}
                  to={`/myApp/menu/admin/my-operators/details/${operatorData.id}`}
                >
                  <ListItem
                    firstName={operatorData.firstName}
                    lastName={operatorData.lastName}
                  />
                </Link>
              ))}
            </div>

            <Pagination
              pageCount={page ? page.totalPages : 0}
              pageRange={3}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <h2 className="page-subtitle">Ainda não há operador cadastrado.</h2>
        )}

        <div className="text-end">
          <Link to={'/myApp/menu/admin/my-operators/register'}>
            <button className="btn btn-secondary  mt-5">
              Cadastrar operador
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Operator;
