import './assets/styles/custom.scss';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import 'styles/table.css';
import 'styles/navbar.css';
import 'styles/dashboard.css';
import 'styles/typography.css';
import 'styles/animated-list.css';
import 'styles/color-pallete.css';

import { useState } from 'react';

import { ToastContainer } from 'react-toastify';

import Routes from 'Routes';

import { AuthContext, AuthContextData } from 'contex/AuthContext';

const App = () => {
  const [authContextData, setAuthContextData] = useState<AuthContextData>({
    authenticated: false,
    userDocument: false,
  });

  return (
    <AuthContext.Provider value={{ authContextData, setAuthContextData }}>
      <Routes />
      <ToastContainer />
    </AuthContext.Provider>
  );
};

export default App;
